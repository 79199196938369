<template>
  <div>
    <kn-form-subtitle title="Agregar salón de clases" />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12" 
          md="4" 
          lg="4" 
          xl="4"
        >
          <v-subheader>Nombre del salón:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="classroom.nombre_salon"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Ocupación máxima:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-text-field
            v-model.number="classroom.ocupacion_maxima"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Sucursal:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="classroom.id_sucursal"
            dense
            outlined
            :rules="[rules.required]"
            :items="sucursals"
            item-value="id"
            item-text="nombre_sucursal"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle title="Listado de salones" divider-position="bottom" />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeClassrooms">
          <v-list-item :key="item.nombre_salon">
            <v-list-item-content>
              <v-text-field
                v-if="toUpdate && item.id === classroom.id"
                class="mb-0 pb-0"
                v-model="classroom.nombre_salon"
                dense
                outlined
              />
              <v-list-item-title
                v-else
                class="purple--text"
                v-text="item.nombre_salon"
              />
              <v-text-field
                v-if="toUpdate && item.id === classroom.id"
                class="mb-0 pb-0"
                v-model.number="classroom.ocupacion_maxima"
                dense
                outlined
              />
              <v-list-item-subtitle
                v-else
                class="text--primary"
                v-text="`Ocupación máxima: ${item.ocupacion_maxima}`"
              />
              <v-select
                v-if="toUpdate && item.id === classroom.id"
                class="mb-0 pb-0"
                v-model="classroom.id_sucursal"
                dense
                outlined
                :items="sucursals"
                item-value="id"
                item-text="nombre_sucursal"
              />
              <v-list-item-subtitle
                v-else
                v-text="`Sucursal: ${item.sucursal.nombre_sucursal}`"
              />
            </v-list-item-content>

            <v-list-item-action>
              <v-row v-if="toUpdate && item.id === classroom.id" no-gutters>
                <v-col>
                  <v-btn
                    icon
                    color="primary"
                    @click="clearInline"
                    title="Cancelar"
                  >
                    <v-icon color="primary"> mdi-cancel </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    color="primary"
                    @click="save"
                    title="Guardar cambios"
                  >
                    <v-icon color="primary"> mdi-content-save-outline </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else no-gutters>
                <v-col>
                  <v-btn
                    icon
                    color="primary"
                    @click="edit(item)"
                    title="Editar"
                  >
                    <v-icon color="primary"> mdi-pencil-outline </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    color="primary"
                    @click="deleteInline(item.id)"
                    title="Eliminar"
                  >
                    <v-icon color="primary"> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeClassrooms.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api } from "@/api/axios-base";
import { validationFormMixin } from "@/mixins/validationFormMixin";
export default {
  components: {
    KnFormSubtitle: () => import("@/components/KnFormSubtitle.vue"),
    KnFormActionButtons: () => import("@/components/KnFormActionButtons.vue"),
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      classroom: {
        id: 'NA', // No asignado
        nombre_salon: null,
        ocupacion_maxima: null,
        id_sucursal: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      classrooms: [],
      sucursals: [],
      toUpdate: false,
    };
  },
  computed: {
    ...mapState(["institutionId", "userData"]),
    actionTitle() {
      return this.toUpdate ? "Actualizar" : "Crear";
    },
    activeClassrooms() {
      return this.classrooms.filter(
        (classroom) => classroom.estatus_sistema === true
      );
    },
    invalidFields() {
      return this.objectHasNulls(this.classroom);
    },
  },
  async created() {
    this.classroom.id_autor = this.userData.id;
    this.classroom.id_institucion_educativa = this.institutionId;
    this.classrooms = await this.fetchClassroomByEI(this.institutionId);
    this.sucursals = await this.fetchSucursalsByEI(this.institutionId);
  },
  methods: {
    async fetchSucursalsByEI(institutionId) {
      try {
        const response = await api.get(
          `/personas/api/sucursal-by-ie/?id_institucion=${institutionId}`
        );
        const responseData = await response.data;
        return responseData;
      } catch (error) {
        console.error(
          "Error al intentar obtener cuentas bancarias por institucion educativa",
          error
        );
      }
    },
    async fetchClassroomByEI(institutionId) {
      try {
        const response = await api.get(
          `/personas/api/salon-clases-by-ie/?id_institucion=${institutionId}`
        );
        const responseData = await response.data;
        return responseData;
      } catch (error) {
        console.error(
          "Error al intentar obtener cuentas bancarias por institucion educativa",
          error
        );
      }
    },
    async createClassroom() {
      try {
        const response = await api.post("/personas/crear-salon-clases", {
          nombre_salon: this.classroom.nombre_salon,
          ocupacion_maxima: this.classroom.ocupacion_maxima,
          id_sucursal: this.classroom.id_sucursal,
          id_autor: this.classroom.id_autor,
          id_institucion_educativa: this.classroom.id_institucion_educativa,
        });
        const responseData = await response.data;
        if (responseData.e) {
          this.errors.push(
            "Ocurrió un error, no se pudo crear el salon de clases."
          );
        }
      } catch (error) {
        console.error("Error al intentar crear el salon de clases", error);
        this.errors.push("Error al intentar crear el salon de clases");
      }
    },
    async updateClassroom() {
      try {
        const response = await api.patch("/personas/update-salon-clases", {
          id: this.classroom.id,
          nombre_salon: this.classroom.nombre_salon,
          ocupacion_maxima: this.classroom.ocupacion_maxima,
          id_sucursal: this.classroom.id_sucursal,
          id_institucion_educativa: this.institutionId,
        });
        const responseData = await response.data;
        if (responseData.e) {
          this.errors.push(
            "Ocurrió un error, no se pudo actualizar el tipo de gasto"
          );
        }
      } catch (error) {
        console.error("Error al intentar actualizar el salon de clases", error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteClassroom(classroomId) {
      try {
        const response = await api.post("/personas/delete-salon-clases", {
          id_salon_clases: classroomId,
        });
        const responseData = await response.data;
        if (responseData.e) {
          this.errors.push(
            "Ocurrió un error, no se pudo eliminar el salon de clases"
          );
        }
      } catch (error) {
        console.error("Error al intentar eliminar el salon de clases", error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateClassroom();
        this.toUpdate = false;
      } else {
        await this.createClassroom();
        this.clear();
      }
      this.classrooms = await this.fetchClassroomByEI(this.institutionId);
      this.clear();
    },
    clear() {
      this.classroom = {
        id: 'NA', // No asignado
        nombre_salon: null,
        ocupacion_maxima: null,
        id_sucursal: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.classroom = {
        id: 'NA', // No asignado
        nombre_salon: null,
        ocupacion_maxima: null,
        id_sucursal: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.classroom = { ...item };
      this.classroom.id_sucursal = item.sucursal.id;
      // console.log("Item a editar:", this.classroom);
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await this.deleteClassroom(itemId);
      this.classrooms = await this.fetchClassroomByEI(this.institutionId);
      this.clearInline();
    },
  },
};
</script>

<style></style>
